import * as React from 'react'
import { inject, observer } from 'mobx-react'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'
import { Store } from '@festival/exponent/store'
import { RouteManager } from '@festival/route-manager'
import { Exponent } from '@festival/exponent/store'
import ImageMedia from '@festival/component/image-media'
import {
    Button,
    Card, CardBody, CardTitle,
    Col,
    Row
} from 'reactstrap'
import TagList from './tag-list'
import Manifest from '@festival/manifest'
import VersionToggler from '@festival/versionable/version-toggler'

export interface Props {
    exponent: Exponent
    routeManager?: RouteManager
    manifest?: Manifest
}

export interface State {

}

export class ListItem extends React.Component<Props, State> {
    render (): React.ReactNode {
        const { exponent, routeManager, manifest } = this.props

        if (!routeManager || !manifest) {
            return null
        }

        return (
            <Card className="border border-primary rounded-0 exponent">
                <VersionToggler container={exponent} className="position-absolute" style={{ top: 0, zIndex: 3 }}/>
                <CardBody className="p-1">
                    <Row>
                        <Col xs={3} md={4}>
                            { (exponent.active.media && exponent.active.media.sticker) ? (
                                <ImageMedia media={exponent.active.media} name="sticker" version="large" alt={exponent.active.altSticker ? exponent.active.altSticker : exponent.active.name}/>
                            ) : (
                                <div className="d-flex justify-content-center align-items-center m-auto">
                                    <img src={manifest.get('img/no-image.jpg')} alt={exponent.active.altSticker ? exponent.active.altSticker : exponent.active.name} className="w-100 h-100"/>
                                </div>
                            )}
                        </Col>
                        <Col xs={9} md={8}>
                            <CardTitle tag="h5" className="text-primary mb-0">
                                <i className="mdi mdi-pound mr-1"></i>{ exponent.active.name }
                            </CardTitle>
                            <TagList type="exponent" codes={exponent.active.tags} root={routeManager.generate('exponents')}/>

                            <div
                                className="description text-primary my-1"
                                dangerouslySetInnerHTML={{ __html: exponent.active.description }}
                                ></div>

                            { exponent.active.externalLink && (
                                <a
                                    href={exponent.active.externalLink}
                                    target="_blank"
                                    rel="noreferrer"
                                    className="d-inline-block mw-100 text-truncate"
                                >
                                    { exponent.active.externalLink }
                                </a>
                            )}
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        )
    }
}

export default inject('routeManager', 'manifest')(observer(ListItem))