import * as React from 'react'
import { inject, observer } from 'mobx-react'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'
import { Store } from '@festival/exponent/store'
import { RouteManager } from '@festival/route-manager'
import { Exponent } from '@festival/exponent/store'
import {
    Card, CardBody, CardTitle,
    Col
} from 'reactstrap'
import TagList from './tag-list'
import VersionToggler from '@festival/versionable/version-toggler'

export interface Props {
    exponent: Exponent
    routeManager?: RouteManager
}

export interface State {

}

export class ListItemLight extends React.Component<Props, State> {
    render (): React.ReactNode {
        const { exponent, routeManager } = this.props

        if (!routeManager) {
            return null
        }

        return (
            <Card className="border border-primary rounded-0 exponent-light">
                <VersionToggler container={exponent} className="position-absolute" style={{ top: 0, zIndex: 3 }}/>
                <CardBody className="p-1">
                    <CardTitle tag="h5" className="text-primary mb-0">
                        <i className="mdi mdi-pound mr-1"></i>{ exponent.active.name }
                    </CardTitle>

                    <TagList type="exponent" codes={exponent.active.tags} root={routeManager.generate('exponents')}/>

                    { exponent.active.externalLink && (
                        <a
                            href={exponent.active.externalLink}
                            target="_blank"
                            rel="noreferrer"
                            className="d-inline-block mw-100 text-truncate"
                        >
                            { exponent.active.externalLink }
                        </a>
                    )}
                </CardBody>
            </Card>
        )
    }
}

export default inject('routeManager')(observer(ListItemLight))