import * as React from 'react'
import { List } from 'react-festival/build/tag/list'
import { inject } from 'mobx-react'
import { Badge } from 'reactstrap'
import { Tag } from '@festival/tag/store'
import { TagGroup } from '@festival/entities/tag-group'
import cn from 'classnames'

export class TagList extends List {

    getItemClass (tag: Tag, group: TagGroup): string {
        return cn('mx-0', 'tag', 'tag-group', 'tag-group-'+group.color)
    }
}

export default inject('tagStore')(TagList)
